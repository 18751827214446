// ------------------------------
// GOOGLE SEARCH
// ------------------------------

.google-search {
  table {
    width: 100%;
    color: $gray-darkest;
    font-size: .9em;
    line-height: 1.8em;
    border-spacing: 0;
    border: 0;
    margin: 30px 0px;
    word-break: break-all;
  }

  thead tr:first-child {
    color: darken($primary-color, 10%);
    border: 0;
    text-align: center;
  }

  th {font-weight: bold;}
  th:first-child, td:first-child {padding: 5px 10px 5px 10px;}

  thead tr:last-child th {
      border: 0px solid $gray-light;
  }

  tbody td {
      border-bottom: 0px solid $gray-light;
      font-size: .85em;
  }

  td:last-child {
    padding-right: 10px;
  }

  tr, td {
    padding: 5px 10px;
    border: 0px solid $gray-light;
  }

  table thead tbody {
    vertical-align: middle;
  }

  #hplogo {
    background:url(https://www.google.com.hk/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png) no-repeat;
    background-size:68px 23px;
    width:68px;
    height:23px;
    float: left;
    margin-right: 10px;
  }
  @media 
  (-webkit-max-device-pixel-ratio:1),
  (max-resolution:96dpi) {
    #hplogo {
      background:url(https://www.google.com.hk/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png) no-repeat;
      background-size:68px 23px;
    }
  }

  .search-wrap{
    width: 90%;
  }


  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"] {
    max-width: 240px;
    width: 80%;
    height: 24px;
    padding: 0;
    margin-top: 0px;
    background: #FFF;
    border: 1px solid #4E5268;
    border-top-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    font-size: .9em;
    color: $gray-darker;
    vertical-align:middle;

    &:focus {
      border-color: $secondary-color;
    }

    @include placeholder {
      color: $gray;
    }
  }

  input[type="submit"] {
    background:url('/assets/siteinfo/search_bg.png') no-repeat;
    height:26px; 
    width:18%; 
    max-width: 70px;
    padding: 0; 
    margin-top: 0px;
    font-size: .9em;
    margin-left: -7px;
    border: 1px solid #4E5268;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    vertical-align:middle;
    text-align: center;
  }


}








