.friendsdiv {
  margin: 20px 0;

  a {
    display: inline-block;
    text-align: center;
    margin: 8px 10px;
  }
  
  img {
    box-shadow: 5px 5px 12px #888888;
    margin: 10px 10px;
  }

}
