
$gray-lightest:     #F8F8F8;
$gray-lighter:      #EEEEEE;
$gray-light:        #DDDDDD;
$gray:              #CCCCCC;
$gray-dark:         #999999;
$gray-darker:       #666666;
$gray-darkest:      #333333;

$green:             #156F78;
$purple:            #493252;
$red:               #E25440;
$orange:            #FB9C50;
$slate:             #3D4260;
$blue:              #2568A3;
$catred:            #BF1827;
$smain:             #DF9C81;

$primary-color:     $smain;
$secondary-color:   #5BA4E5;

$blockquote-bg:     #FFFFFF;

$link-color:        $primary-color;
$hover-color:       darken($link-color, 15%);

$menu-link-color:        $hover-color;
$menu-hover-color:       darken($menu-link-color, 15%);

$sans-font:         "ff-tisa-web-pro-1","ff-tisa-web-pro-2","Lucida Grande","Hiragino Sans GB","Hiragino Sans GB W3","Microsoft YaHei","WenQuanYi Micro Hei",sans-serif;
$serif-font:        "ff-tisa-web-pro-1","ff-tisa-web-pro-2","Lucida Grande","Hiragino Sans GB","Hiragino Sans GB W3","Microsoft YaHei","WenQuanYi Micro Hei",sans-serif;

$border-radius:     3px;